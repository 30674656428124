.footer_span_desktop:not(:first-child) {
  padding-left: 50px;
}

.footer_span_mobile:first-child {
  display: block;
}

.footer_span_mobile:not(:first-child) {
  padding: 0px 15px;
}
