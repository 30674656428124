.height-100-percent {
  height: 100%;
}
.height-70-percent {
  height: 70%;
}
.height-50px {
  height: 50px;
}
.height-7px {
  height: 7px;
}
