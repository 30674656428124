.margin-horizontal-auto {
  margin: 0 auto;
}
.margin-right-20px {
  margin-right: 20px;
}
.margin-top-20px {
  margin-top: 20px;
}
.margin-top-30px {
  margin-top: 30px;
}
.margin-top-5px {
  margin-top: 5px;
}
.margin-bottom-20px {
  margin-bottom: 20px;
}
.margin-bottom-30px {
  margin-bottom: 30px;
}
.margin-vertical-20px {
  margin: 20px 0;
}
.margin-top-25px {
  margin-top: 25px;
}
.margin-top-70px {
  margin-top: 70px;
}
.margin-top-130px {
  margin-top: 130px;
}
.margin-top-100px {
  margin-top: 100px;
}
.margin-bottom-70px {
  margin-bottom: 70px;
}
@media all and (max-width: 1024px) {
  .margin-zero-tablet {
    margin: 0 !important;
  }
  .margin-bottom-70px-tablet {
    margin-bottom: 70px;
  }
}
@media all and (max-width: 768px) {
  .margin-zero-mobile {
    margin: 0 !important;
  }
}
