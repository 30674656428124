.padding-horizontal-15px {
  padding: 0 15px;
}
.padding-horizontal-10px {
  padding: 0 10px;
}
.padding-vertical-20px {
  padding: 20px 0;
}
.padding-20px {
  padding: 20px;
}
.padding-15px {
  padding: 15px;
}
.padding-vertical-13px {
  padding: 13px 0;
}
.padding-horizontal-25px {
  padding: 0 25px;
}
.padding-40px {
  padding: 40px;
}
