.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-1 {
  flex: 1;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.space-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
@media all and (max-width: 768px) {
  .flex-column-mobile {
    align-items: center;
    flex-direction: column;
  }

  .flex-column-reverse-mobile {
    align-items: center;
    flex-direction: column-reverse;
  }
}
@media all and (max-width: 1024px) {
  .center-tablet {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
