.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-size-15px {
  font-size: 15px;
}
.font-size-70px {
  font-size: 70px;
}
.font-size-40px {
  font-size: 40px;
}
.font-size-30px {
  font-size: 30px;
}
.font-weight-normal {
  font-weight: normal !important;
}
@media all and (max-width: 1024px) {
  .font-size-70px {
    font-size: calc(70px / 2.5);
  }
}
