.rs-orange-background {
  background-color: var(--rs-orange);
}
.rs-maroon-background {
  background-color: var(--rs-maroon);
}
.rs-brown-background {
  background-color: var(--rs-brown);
}
.rs-green-background {
  background-color: var(--rs-green);
}
.border-radius-50px {
  border-radius: 50px;
}
.text-align-center {
  text-align: center;
}
.outline-none {
  outline: none;
}
.border-none {
  border: none;
}
.rs-orange-border {
  border: 1px solid var(--rs-orange);
}
.cursor-pointer {
  cursor: pointer;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.rs-orange-color {
  color: var(--rs-orange);
}
.color-white {
  color: white;
}
.border-radius-50-percent {
  border-radius: 50%  ;
}
.background-none {
  background: none;
}
.footer-link-color {
  color: #e29191;
}

@media all and (max-width: 1024px) {
  .hide-on-tablet {
    display: none;
  }
  .text-align-center-tablet {
    text-align: center;
  }
}

@media all and (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
}

.underline-on-hover:hover {
  text-decoration: underline;
}
