:root {
  --rs-orange: #e54a13;
  --rs-maroon: #720f09;
  --rs-green: #26714B;
  --rs-brown: #b16006;
  --primary-text: #282828;
  --primary-header: #424242;
}

p {
  color: var(--primary-text);
  line-height: 1.5;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  word-break: break-word;
  font-family: 'Poppins', sans-serif;
  min-height: max-content;
}

img {
  display: block;
}

img, svg {
  min-height: initial;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1.35;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
  color: inherit;
}

input[type=search] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.fb-page {
  position: fixed !important;
  bottom: 50px;
  left: 50px;
}
