.position-fixed {
  position: fixed;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.top-zero {
  top: 0;
}
.bottom-zero {
  bottom: 0;
}
.left-zero {
  left: 0;
}
.right-zero {
  right: 0;
}
.z-index--1 {
  z-index: -1;
}

