.width-100-percent {
  width: 100%;
}
.min-width-100-percent {
  min-width: 100%;
}
.width-60-percent {
  width: 60%;
}
.width-50-percent {
  width: 50%;
}
.max-width-1500px {
  max-width: 1500px;
}
.max-width-170px {
  max-width: 170px;
}
.max-width-max-content {
  max-width: max-content;
}
.max-width-400px {
  max-width: 400px;
}
.max-width-639px {
  max-width: 639px;
}
.max-width-571px {
  max-width: 571px;
}
.max-width-1300px {
  max-width: 1300px;
}
.max-width-130px {
  max-width: 130px;
}
.max-width-1040px {
  max-width: 1040px
}
.width-7px {
  width: 7px;
}
@media all and (max-width: 1024px) {
  .full-width-tablet {
    width: 100%;
  }
}

